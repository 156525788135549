import React from "react"
import { ReactNode } from "react"
import "../css/layout.scss"
import { Link } from "gatsby"
import Logo from "./Logo"
import ThemeButton from "./ThemeButton"

interface Props {
  isHomePage?: boolean
  footer?: ReactNode
}

const Layout: React.FC<Props> = ({ children, footer }) => {
  return (
    <div className="root">
      <div className="container">
        <div className="top">
          <div className="logoWrapper">
            <Link to="/">
              <Logo />
            </Link>
            <ThemeButton />
          </div>
          {children}
        </div>
      </div>
      {footer && (
        <footer className="bottom">
          <div className="inner">{footer}</div>
        </footer>
      )}
    </div>
  )
}

export default Layout
